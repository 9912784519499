import styles from './styles.module.scss';

type ServiceTermProps = {
  onToggleClick: () => void;
};

export default function ServiceTerm({ onToggleClick }: ServiceTermProps) {
  return (
    <div className={styles.termsContainer}>
      <h1>이용약관(필수)</h1>
      <div>
        <h1 className={styles.title} style={{ marginBottom: '1rem' }}>
          총칙
        </h1>
        <article>
          <h1 className={styles.title}>제 1조(목적)</h1>
          <p>
            주말내집 서비스 이용약관은 주말내집 (이하 “회사”)가 서비스를
            제공함에 있어, 회사와 회원 간의 권리, 의무 및 책임 사항 등을
            규정함을 목적으로 합니다.
          </p>
        </article>
        <article>
          <h1 className={styles.title}>제 2조(정의)</h1>
          <ol>
            <li>이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</li>
            <ul>
              <li>&apos;회사&apos;란, 서비스를 제공하는 주체를 말합니다.</li>
              <li>
                &apos;서비스&apos;란, 회사가 제공하는 모든 서비스 및 기능을
                말합니다.
              </li>
              <li>
                &apos;회원&apos;이란, 약관에 따라 서비스에 회원등록을 하고
                서비스를 이용하는 자를 말합니다.
              </li>
              <li>
                &apos;계정&apos;이란, 이용계약을 통해 생성된 회원의 고유
                아이디와 이에 수반하는 정보를 말합니다.
              </li>
              <li>
                &apos;콘텐츠&apos;란, 서비스에서 제공하는 모든 종류의 ‘대화
                주제’ 등을 말합니다.
              </li>
              <li>
                &apos;관련법&apos;이란, 정보통신망 이용촉진 및 정보보호 등에
                관한 법률, 개인정보보호법, 통신비밀보호법을 비롯한 국내 법령을
                말합니다.
              </li>
            </ul>
            <li>
              1항에서 정의되지 않는 약관 내 용어의 의미는 일반적인 이용관행에
              의합니다.
            </li>
          </ol>
        </article>
        <article>
          <h1 className={styles.title}>
            제 3조(약관 등의 명시와 설명 및 개정)
          </h1>
          <ol>
            <li>회사는 이 약관을 회원가입 화면 및 페이지 하단에 게시합니다.</li>
            <li>
              회사는 관련법을 위배하지 않는 범위 내에서 이 약관을 개정할 수
              있습니다.
            </li>
            <li>
              개정 내용이 회원에게 불리할 경우, 적용일자 및 개정사유를 명시하여
              현행약관과 함께 알림 수단을 통해 고지하며 변경될 내용을 변경 30일
              전에 미리 알려드리겠습니다.
            </li>
            <li>
              회원이 개정약관의 적용에 동의하지 않는 경우, 이용계약을
              해지함으로써 거부 의사를 표시할 수 있습니다. 단, 30일 내에 거부
              의사 표시를 하지 않을 경우 약관에 동의한 것으로 간주합니다.
            </li>
            <li>회원은 본 약관의 일부분만을 동의 또는 거부할 수 없습니다.</li>
          </ol>
        </article>
        <article>
          <h1 className={styles.title}>제 4조(서비스의 제공)</h1>
          <ol>
            <li>회사는 다음 서비스를 제공합니다.</li>
            <ul>
              <li>오도이촌 소개 서비스</li>
              <li>커뮤니티 서비스</li>
              <li>마이페이지 서비스</li>
              <li>이벤트, 프로모션, 광고 정보 제공 서비스</li>
              <li>기타 회사가 정하는 서비스</li>
            </ul>
            <li>
              회사는 운영상, 기술상의 필요에 따라 제공하고 있는 서비스를 변경할
              수 있습니다.
            </li>
            <li>
              회사는 회원의 개인정보 및 서비스 이용 기록에 따라 서비스 이용에
              차이를 둘 수 있습니다.
            </li>
            <li>
              회사는 천재지변, 인터넷 장애, 경영 악화 등으로 인해 서비스를
              더이상 제공하기 어려울 경우, 서비스를 통보 없이 중단할 수
              있습니다.
            </li>
            <li>
              회사는 1항부터 전항까지와 다음 내용으로 인해 발생한 피해에 대해
              어떠한 책임도 지지 않습니다.
            </li>
            <ul>
              <li>
                모든 서비스, 콘텐츠, 이용 기록의 진본성, 무결성, 신뢰성,
                이용가능성
              </li>
              <li>
                서비스 내에서 성사된 모임에서 발생한 금전적, 신체적, 정신적 피해
              </li>
              <li>
                온라인, 오프라인에서의 서비스 이용 중 사용자에게 발생한 피해
              </li>
              <li>
                광고의 버튼, 하이퍼링크 등 외부로 연결된 서비스와 같이 회사가
                제공하지 않은 서비스에서 발생한 피해
              </li>
              <li>
                회원의 귀책 사유 또는 회사의 귀책 사유가 아닌 사유로 발생한
                회원의 피해
              </li>
            </ul>
          </ol>
        </article>
        <article>
          <h1 className={styles.title}>제 5조(서비스 이용계약의 성립)</h1>
          <ol>
            <li>
              회사와 회원의 서비스 이용계약은 서비스 내부의 회원가입 화면 가입
              양식에 따라 회원정보를 기입한 후 필수 약관에 동의한다는 의사표시를
              하면서 체결됩니다.
            </li>
            <li>
              회원은 회사에서 제공하는 절차에 따라 회원가입을 진행해야 하며,
              반드시 실제 이메일과 생년월일을 사용해야 합니다. 실제 정보를
              입력하지 않은 이용자는 법적인 보호를 받을 수 없으며, 서비스 이용에
              제한을 받을 수 있습니다
            </li>
          </ol>
        </article>
        <article>
          <h1 className={styles.title}>제 6조(개인정보의 관리 및 보호)</h1>
          <ol>
            <li>
              회원이 회사와 체결한 서비스 이용계약은 처음 이용계약을 체결한
              본인에 한해 적용됩니다.
            </li>
            <li>
              회원의 이메일과 비밀번호, 닉네임 등 모든 개인정보의 관리책임은
              본인에게 있으므로, 타인에게 양도 및 대여할 수 없으며, 유출되지
              않도록 관리해야합니다. 만약 본인의 이메일 및 비밀번호를 타인이
              사용하고 있음을 인지했을 경우 즉시 비밀번호를 변경해야 합니다.
            </li>
            <li>
              회사는 2항부터 전항까지를 이행하지 않아 발생한 피해에 대해 어떠한
              책임을 지지 않습니다.
            </li>
          </ol>
        </article>
        <article>
          <h1 className={styles.title}>제 7조(서비스 이용계약의 종료)</h1>
          <ol>
            <li>
              회원은 언제든지 본인의 계정으로 로그인한 뒤 서비스 내부의
              &apos;회원 탈퇴&apos; 버튼을 누르는 방법으로 탈퇴를 요청할 수
              있으며, 문의 창구를 통한 탈퇴 요청 등은 처리되지 않습니다. 회사는
              해당 요청을 확인한 후 탈퇴를 처리합니다.
            </li>
            <li>
              회사는 천재지변, 서비스 종료 등 불가피한 사유로 더이상 서비스를
              제공할 수 없을 경우, 회원의 동의 없이 회원자격을 박탈할 수
              있습니다.
            </li>
            <li>
              회사는 1항부터 전항까지로 인해 발생한 피해에 대해 어떠한 책임을
              지지 않습니다.
            </li>
          </ol>
        </article>
        <article>
          <h1 className={styles.title}>제 8조(회원에 대한 통보)</h1>
          <ol>
            <li>
              회사가 회원에 대한 통보를 하는 경우, 회원이 회원가입 시 입력한
              이메일을 이용합니다.
            </li>
            <li>
              회사는 다수의 회원에 대한 통보를 할 경우 서비스 내부 알림 메시지를
              띄우는 등의 방법을 통해 개별 통보에 갈음할 수 있습니다.
            </li>
            <li>
              회원이 30일 이내에 의사표시를 하지 않을 경우, 통보 내용에 대해
              동의한 것으로 간주합니다.
            </li>
          </ol>
        </article>
        <article>
          <h1 className={styles.title}>제 9조(저작권의 귀속)</h1>
          <ol>
            <li>
              회사는 유용하고 편리한 서비스를 제공하기 위해, 2022년부터 서비스
              및 서비스 내부의 기능 체계와 다양한 기능을 직접 설계 및 운영하고
              있는 데이터베이스 제작자에 해당합니다. 회사는 저작권법에 따라
              데이터베이스 제작자는 복제권 및 전송권을 포함한 데이터베이스
              전부에 대한 권리를 가지고 있으며, 이는 법률에 따라 보호를 받는
              대상입니다. 그러므로 회원은 데이터 베이스 제작자인 회사의 승인
              없이 데이터베이스의 전부 또는 일부를 복제・배포・방송 또는 전송할
              수 없습니다.
            </li>
            <li>
              회사가 작성한 콘텐츠에 대한 권리는 회사에 귀속됩니다. 회원은
              별도의 허락 없이 회사의 콘텐츠에 대해 본래의 서비스 이용을 제외한
              목적으로 사용, 수정, 배포할 수 없습니다
            </li>
          </ol>
        </article>
        <article>
          <h1 className={styles.title}>제 10조(광고의 게재)</h1>
          <ol>
            <li>
              회사는 서비스의 운용과 관련하여 서비스 화면, 홈페이지, 이메일 등에
              광고를 게재할 수 있습니다.
            </li>
            <li>
              회사는 서비스에 게재되어 있는 광고주의 판촉활동에 회원이
              참여하거나 교신 또는 거래의 결과로 인해 발생하는 모든 손실 및
              손해에 대해 책임을 지지 않습니다.
            </li>
          </ol>
        </article>
        <article>
          <h1 className={styles.title}>제 11조(금지행위)</h1>
          <ol>
            <li>회원에 의한 다음 행위는 금지되어 있습니다.</li>
            <ul>
              <li>개인정보 또는 계정 기만, 침해, 공유 행위</li>
              <li>개인정보를 허위, 누락, 오기, 도용하여 작성하는 행위</li>
              <li>타인의 개인정보 및 계정을 수집, 저장, 공개, 이용하는 행위</li>
              <li>자신과 타인의 개인정보를 제 3자에게 공개, 양도하는 행위</li>
              <li>시스템 부정행위</li>
              <li>허가하지 않은 방식의 서비스 이용 행위</li>
              <li>회사의 모든 재산에 대한 침해 행위</li>
              <li>업무 방해 행위</li>
              <li>
                서비스 관리자 또는 이에 준하는 자격을 사칭하거나 허가없이
                취득하여 직권을 행사하는 행위
              </li>
              <li>회사 및 타인의 명예를 손상시키거나 업무를 방해하는 행위</li>
              <li>
                서비스 내부 정보 일체를 허가 없이 이용, 변조, 삭제 및 외부로
                유출하는 행위
              </li>
              <li>
                이 약관, 개인정보 처리방침에서 이행 및 비이행을 명시한 내용에
                반하는 행위
              </li>
              <li>기타 현행법에 어긋나거나 부적절하다고 판단되는 행위</li>
            </ul>
            <li>
              회원이 1항에 해당하는 행위를 할 경우, 회사는 다음과 같은 조치를
              영구적으로 취할 수 있습니다.
            </li>
            <ul>
              <li>회원의 서비스 이용 권한, 자격, 혜택 제한 및 회수</li>
              <li>회원과 체결된 이용계약을 회원의 동의나 통보 없이 파기</li>
              <li>회원가입 거부</li>
              <li>그 외 회사가 필요하다고 판단되는 조치</li>
            </ul>
            <li>
              회사는 1항부터 전항까지로 인해 발생한 피해에 대해 어떠한 책임을
              지지 않으며, 회원은 귀책사유로 인해 발생한 모든 손해를 배상할
              책임이 있습니다.
            </li>
          </ol>
        </article>
        <article>
          <h1 className={styles.title}>제 12조(재판권 및 준거법)</h1>
          <ol>
            <li>
              회사와 회원 간에 발생한 분쟁에 관한 소송은 대한민국
              서울중앙지방법원을 관할 법원으로 합니다. 다만, 제소 당시 회원의
              주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는
              민사소송법상의 관할법원에 제기합니다.
            </li>
            <li>회사와 회원 간에 제기된 소송에는 한국법을 적용합니다.</li>
          </ol>
        </article>
        <article>
          <h1 className={styles.title}>제 13조(기타)</h1>
          <ol>
            <li>이 약관은 2023년 1월 27일에 제정되었습니다.</li>
            <li>
              이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 관련법
              또는 관례에 따릅니다.
            </li>
            <li>
              이 약관에도 불구하고 다른 약관이나 서비스 이용 중 안내 문구 등으로
              달리 정함이 있는 경우에는 해당 내용을 우선으로 합니다.
            </li>
          </ol>
        </article>
      </div>
      <button type="button" onClick={onToggleClick}>
        확인
      </button>
    </div>
  );
}
